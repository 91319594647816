import { WalkKeep, Walker, Finder } from './types';
import logger from './logger';

/**
 * `DOM` 元素遍历函数
 */
export interface ElementWalker extends Walker<Element> {}

/**
 * `DOM` 元素查找函数
 */
export interface ElementFinder extends Finder<Element> {}

/**
 * 获取 `DOM` 元素索引
 * @param element 元素
 * @returns 元素索引
 */
export function getElementIndex(element: Element) {
  if (element.parentElement) {
    return Array.from(element.parentElement.children).indexOf(element);
  }

  return 0;
}

/**
 * 向下遍历 `DOM` 元素
 * @param walker 遍历函数
 * @param element 遍历元素
 * @param depth 遍历深度，默认值为 `0`
 * @param index 遍历索引，默认值为遍历元素索引
 * @returns `false`: 提前退出遍历；`true` | `void`: 完全遍历
 */
export function walkDownElement(
  walker: ElementWalker,
  element: Element,
  depth: number = 0,
  index: number = getElementIndex(element),
): WalkKeep {
  let keep = walker(element, depth, index);

  if (keep !== false) {
    const childElements = Array.from(element.children);
    const nextDepth = depth + 1;
    let index = 0;

    for (const childElement of childElements) {
      keep = walkDownElement(walker, childElement, nextDepth, index);

      index += 1;

      if (keep === false) {
        break;
      }
    }
  }

  return keep;
}

/**
 * 向上遍历 `DOM` 元素
 * @param walker 遍历函数
 * @param element 遍历元素
 * @param depth 遍历深度，默认值为 `0`
 * @param index 遍历索引，默认值为遍历元素索引
 * @returns `false`: 提前退出遍历；`true` | `void`: 完全遍历
 */
export function walkUpElement(
  walker: ElementWalker,
  element: Element,
  depth: number = 0,
  index: number = getElementIndex(element),
): WalkKeep {
  let keep = walker(element, depth, index);

  if (keep !== false && element.parentElement) {
    keep = walkUpElement(walker, element.parentElement, depth - 1);
  }

  return keep;
}

/**
 * 向右遍历 `DOM` 元素
 * @param walker 遍历函数
 * @param element 遍历元素
 * @param depth 遍历深度，默认值为 `0`
 * @param index 遍历索引，默认值为遍历元素索引
 * @returns `false`: 提前退出遍历；`true` | `void`: 完全遍历
 */
export function walkRightElement(
  walker: ElementWalker,
  element: Element,
  depth: number = 0,
  index: number = getElementIndex(element),
): WalkKeep {
  let keep = walker(element, depth, index);

  if (keep !== false && element.nextElementSibling) {
    keep = walkRightElement(walker, element.nextElementSibling, depth, index + 1);
  }

  return keep;
}

/**
 * 向左遍历 `DOM` 元素
 * @param walker 遍历函数
 * @param element 遍历元素
 * @param depth 遍历深度，默认值为 `0`
 * @param index 遍历索引，默认值为遍历元素索引
 * @returns `false`: 提前退出遍历；`true` | `void`: 完全遍历
 */
export function walkLeftElement(
  walker: ElementWalker,
  element: Element,
  depth: number = 0,
  index: number = getElementIndex(element),
): WalkKeep {
  let keep = walker(element, depth, index);

  if (keep !== false && element.previousElementSibling) {
    keep = walkLeftElement(walker, element.previousElementSibling, depth, index - 1);
  }

  return keep;
}

/**
 * 查询 `DOM` 元素
 * @param finder 查询函数
 * @param entry 入口元素，默认值为 `document.documentElement`
 * @returns `DOM` 元素节点，`null` 表示没有满足查询的结果
 */
export function queryElement(
  finder: ElementFinder,
  entry: Element = document.documentElement,
): Element | null {
  let result: Element | null = null;

  walkDownElement((element, depth, index) => {
    logger.debug('↓ walk down element at <depth: %s, index: %s>: %o', depth, index, element);

    if (finder(element, depth, index)) {
      result = element;

      logger.verbose(
        '• stop walk, found element at <depth: %s, index: %s>: %o',
        depth,
        index,
        element,
      );

      return false;
    }

    return undefined;
  }, entry);

  return result;
}

/**
 * 查询 `DOM` 元素 (复数)
 * @param finder 查询函数
 * @param entry 入口元素，默认值为 `document.documentElement`
 * @returns `DOM` 元素节点 (复数)
 */
export function queryElements(
  finder: ElementFinder,
  entry: Element = document.documentElement,
): Element[] {
  const results: Element[] = [];

  walkDownElement((element, depth, index) => {
    logger.debug('↓ walk down element at <depth: %s, index: %s>: %o', depth, index, element);

    if (finder(element, depth, index)) {
      results.push(element);

      logger.verbose(
        '⊙ stop walk, found element at <depth: %s, index: %s>: %o',
        depth,
        index,
        element,
      );
    }
  }, entry);

  return results;
}
