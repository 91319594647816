import debug, { Debugger } from 'debug';

import { Logger } from './types';

class DebugLogger<T = any> implements Logger<T> {
  static enable(namespace: string) {
    return debug.enable(namespace);
  }

  private _namespace: string;

  private _log: Debugger;

  private _silly: Debugger;

  private _debug: Debugger;

  private _verbose: Debugger;

  private _info: Debugger;

  private _warn: Debugger;

  private _error: Debugger;

  constructor(namespace: string) {
    this._namespace = namespace;
    this._log = debug(namespace);
    this._silly = this._log.extend('silly');
    this._debug = this._log.extend('debug');
    this._verbose = this._log.extend('verbose');
    this._info = this._log.extend('info');
    this._warn = this._log.extend('warn');
    this._error = this._log.extend('error');
  }

  getNamespace() {
    return this._namespace;
  }

  setNamespace(namespace: string) {
    if (this._namespace !== namespace) {
      this._namespace = namespace;
      this._log = debug(namespace);
      this._silly = this._log.extend('silly');
      this._debug = this._log.extend('debug');
      this._verbose = this._log.extend('verbose');
      this._info = this._log.extend('info');
      this._warn = this._log.extend('warn');
      this._error = this._log.extend('error');
    }

    return this;
  }

  silly(message?: T, ...optionalParams: any[]) {
    return this._silly(message, ...optionalParams);
  }

  debug(message?: T, ...optionalParams: any[]) {
    return this._debug(message, ...optionalParams);
  }

  verbose(message?: T, ...optionalParams: any[]) {
    return this._verbose(message, ...optionalParams);
  }

  info(message?: T, ...optionalParams: any[]) {
    return this._info(message, ...optionalParams);
  }

  warn(message?: T, ...optionalParams: any[]) {
    return this._warn(message, ...optionalParams);
  }

  error(message?: T, ...optionalParams: any[]) {
    return this._error(message, ...optionalParams);
  }
}

export default DebugLogger;
