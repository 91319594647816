import { Logger } from './types';
import ProxyLogger from './ProxyLogger';

class LoggerManager<K = any, M = any> {
  private _rootLogger: Logger<M>;

  private _proxyLoggerMap: Map<K, ProxyLogger<M>>;

  private _loggerMap: Map<K, Logger<M>>;

  constructor(rootLogger: Logger<M>) {
    this._rootLogger = rootLogger;
    this._proxyLoggerMap = new Map<K, ProxyLogger<M>>();
    this._loggerMap = new Map<K, Logger<M>>();
  }

  get size() {
    return this._proxyLoggerMap.size;
  }

  forEach(iteratee: (value: ProxyLogger<M>, key: K, map: Map<K, ProxyLogger<M>>) => void) {
    this._proxyLoggerMap.forEach(iteratee);

    return this;
  }

  getRootLogger() {
    return this._rootLogger;
  }

  setRootLogger(rootLogger: Logger<M>) {
    if (this._rootLogger !== rootLogger) {
      this._proxyLoggerMap.forEach((proxyLogger, key) => {
        if (this._rootLogger === proxyLogger.getLogger() && !this._loggerMap.has(key)) {
          proxyLogger.setLogger(rootLogger);
        }
      });

      this._rootLogger = rootLogger;
    }

    return this;
  }

  getLogger(key: K) {
    if (!this._proxyLoggerMap.has(key)) {
      const proxyLogger = new ProxyLogger(this._rootLogger);
      this._proxyLoggerMap.set(key, proxyLogger);
    }

    return this._proxyLoggerMap.get(key) as ProxyLogger<M>;
  }

  setLogger(key: K, logger: Logger<M>) {
    this._loggerMap.set(key, logger);

    if (this._proxyLoggerMap.has(key)) {
      const proxyLogger = this._proxyLoggerMap.get(key) as ProxyLogger<M>;

      proxyLogger.setLogger(logger);
    } else {
      const proxyLogger = new ProxyLogger(logger);

      this._proxyLoggerMap.set(key, proxyLogger);
    }

    return this;
  }
}

export default LoggerManager;
