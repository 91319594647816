import { queryElement, queryElements, Walker, Finder, WalkKeep } from '@leyan/fiber-helper';

import { VM } from './types';
import logger from './logger';

/**
 * `vue` 实例遍历函数
 */
export interface VMWalker extends Walker<VM> {}

/**
 * `vue` 实例查询函数
 */
export interface VMFinder extends Finder<VM> {}

/**
 * 判断是否是 `vue` 实例
 * @param target 目标
 * @returns `ture`: 是，`false`: 不是
 */
export function isVM(target: any): target is VM {
  const candidate = target as VM;

  return (
    Boolean(candidate) &&
    candidate.$root !== undefined &&
    '$vnode' in candidate &&
    '$parent' in candidate &&
    Array.isArray(candidate.$children)
  );
}

/**
 * 判断是否是 `vue` 根实例
 * @param vm `vue` 实例
 * @returns `true`: 是，`false`: 不是
 */
export function isRootVM(vm: VM) {
  return vm === vm.$root || vm.$el === vm.$root.$el;
}

/**
 * 从 `DOM` 元素获取 `vue` 实例
 * @param element 元素
 * @returns `vue` 实例， `null`: 表示获取失败
 */
export function getVMFromElement(element: Element) {
  const vm = (element as any).__vue__;

  if (vm && isVM(vm)) {
    return vm;
  }

  return null;
}

/**
 * 从 `DOM` 元素获取 `vue` 根实例
 * @param element 元素
 * @returns `vue` 根实例，`null`: 表示获取失败
 */
export function getRootVMFromElement(element: Element) {
  const vm = getVMFromElement(element);

  if (vm && isRootVM(vm)) {
    return vm;
  }

  return null;
}

/**
 * 判断是否是 `vue` 根实例 `DOM` 元素
 * @param element 元素
 * @returns `true`: 是，`false`: 不是
 */
export function isVueRootElement(element: Element) {
  const vm = getRootVMFromElement(element);

  return Boolean(vm);
}

/**
 * 获取 `vue` 实例索引
 * @param vm 实例
 * @returns 实例索引
 */
export function getVMIndex(vm: VM) {
  if (vm.$parent) {
    return vm.$parent.$children.indexOf(vm);
  }

  return 0;
}

/**
 * 向下遍历 `vue` 实例
 * @param walker 遍历函数
 * @param vm 遍历实例
 * @param depth 遍历深度，默认值为 `0`
 * @param index 遍历索引，默认值为遍历实例索引
 * @returns `false`: 提前退出遍历；`true` | `void`: 完全遍历
 */
export function walkDownVM(
  walker: VMWalker,
  vm: VM,
  depth: number = 0,
  index: number = getVMIndex(vm),
): WalkKeep {
  let keep = walker(vm, depth, index);

  if (keep !== false) {
    const childVMs = vm.$children;
    const nextDepth = depth + 1;
    let index = 0;

    for (const childVM of childVMs) {
      keep = walkDownVM(walker, childVM, nextDepth, index);

      index += 1;

      if (keep === false) {
        break;
      }
    }
  }

  return keep;
}

/**
 * 向上遍历 `vue` 实例
 * @param walker 遍历函数
 * @param vm 遍历实例
 * @param depth 遍历深度，默认值为 `0`
 * @param index 遍历索引，默认值为遍历实例索引
 * @returns `false`: 提前退出遍历；`true` | `void`: 完全遍历
 */
export function walkUpVM(
  walker: VMWalker,
  vm: VM,
  depth: number = 0,
  index: number = getVMIndex(vm),
): WalkKeep {
  let keep = walker(vm, depth, index);

  if (keep !== false && vm.$parent) {
    keep = walkUpVM(walker, vm.$parent, depth - 1);
  }

  return keep;
}

/**
 * 向右遍历 `vue` 实例
 * @param walker 遍历函数
 * @param vm 遍历实例
 * @param depth 遍历深度，默认值为 `0`
 * @param index 遍历索引，默认值为遍历实例索引
 * @returns `false`: 提前退出遍历；`true` | `void`: 完全遍历
 */
export function walkRightVM(
  walker: VMWalker,
  vm: VM,
  depth: number = 0,
  index: number = getVMIndex(vm),
): WalkKeep {
  let keep = walker(vm, depth, index);

  if (keep !== false && vm.$parent) {
    const childVMs = vm.$parent.$children;

    for (let nextIndex = index + 1; nextIndex < childVMs.length; nextIndex += 1) {
      keep = walker(childVMs[nextIndex], depth, nextIndex);

      if (keep === false) {
        break;
      }
    }
  }

  return keep;
}

/**
 * 向左遍历 `vue` 实例
 * @param walker 遍历函数
 * @param vm 遍历实例
 * @param depth 遍历深度，默认值为 `0`
 * @param index 遍历索引，默认值为遍历实例索引
 * @returns `false`: 提前退出遍历；`true` | `void`: 完全遍历
 */
export function walkLeftVM(
  walker: VMWalker,
  vm: VM,
  depth: number = 0,
  index: number = getVMIndex(vm),
): WalkKeep {
  let keep = walker(vm, depth, index);

  if (keep !== false && vm.$parent) {
    const childVMs = vm.$parent.$children;

    for (let nextIndex = index - 1; nextIndex >= 0; nextIndex -= 1) {
      keep = walker(childVMs[nextIndex], depth, nextIndex);

      if (keep === false) {
        break;
      }
    }
  }

  return keep;
}

/**
 * 查询 `vue` 根元素
 * @param entry 入口元素，默认值为 `document.body`
 * @returns `vue` 根元素，`null`: 表示没查询到 `vue` 根元素
 */
export function queryVueRootElement(entry: Element = document.body) {
  return queryElement((element) => {
    return isVueRootElement(element);
  }, entry);
}

/**
 * 查询 `vue` 根元素 (复数)
 * @param entry 入口元素，默认值为 `document.body`
 * @returns `vue` 根元素 (复数)
 */
export function queryVueRootElements(entry: Element = document.body) {
  return queryElements((element) => {
    return isVueRootElement(element);
  }, entry);
}

/**
 * 查询 `vue` 根实例
 * @param entry 入口元素，默认值为 `document.body`
 * @returns `vue` 根实例，`null`: 表示未查询到 `vue` 根实例
 */
export function queryRootVM(entry: Element = document.body) {
  const vueRootElement = queryVueRootElement(entry);

  if (vueRootElement) {
    return getRootVMFromElement(vueRootElement);
  }

  return null;
}

/**
 * 查询 `vue` 根实例 (复数)
 * @param entry 入口元素，默认值为 `document.body`
 * @returns `vue` 根实例 (复数)
 */
export function queryRootVMs(entry: Element = document.body) {
  const vueRootElements = queryVueRootElements(entry);

  return vueRootElements.reduce<VM[]>((vms, element) => {
    const vm = getRootVMFromElement(element);

    if (vm) {
      vms.push(vm);
    }

    return vms;
  }, []);
}

/**
 * 查询 `vue` 实例
 * @param finder 查询函数
 * @param entry 入口节点，默认值为所有 `vue` 根实例
 * @returns `vue` 实例，`null`: 表示没有查询到 `vue` 实例
 */
export function queryVM(finder: VMFinder, entry: VM | VM[] = queryRootVMs()): VM | null {
  const entries = Array.isArray(entry) ? entry : [entry];
  let result: VM | null = null;
  let keep;

  const walker: VMWalker = (vm, depth, index) => {
    logger.debug('↓ walk down vm at <depth: %s, index: %s>: %o', depth, index, vm);

    if (finder(vm, depth, index)) {
      result = vm;

      logger.verbose('• stop walk, found vm at <depth: %s, index: %s>: %o', depth, index, vm);

      return false;
    }

    return undefined;
  };

  for (const entry of entries) {
    keep = walkDownVM(walker, entry);

    if (keep === false) {
      break;
    }
  }

  return result;
}

/**
 * 查询 `vue` 实例 (复数)
 * @param finder 查询函数
 * @param entry 入口节点，默认值为所有 `vue` 根实例
 * @returns `vue` 实例 (复数)
 */
export function queryVMs(finder: VMFinder, entry: VM | VM[] = queryRootVMs()) {
  const entries = Array.isArray(entry) ? entry : [entry];
  const result: VM[] = [];

  const walker: VMWalker = (vm, depth, index) => {
    logger.debug('↓ walk down vm at <depth: %s, index: %s>: %o', depth, index, vm);

    if (finder(vm, depth, index)) {
      result.push(vm);

      logger.verbose('⊙ found vm at <depth: %s, index: %s>: %o', depth, index, vm);
    }
  };

  for (const entry of entries) {
    walkDownVM(walker, entry);
  }

  return result;
}
