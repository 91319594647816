import { isPromiseLike } from './utils';

const ONCE_HANDLE_META_SYMBOL = Symbol.for('ONCE_HANDLE_META_SYMBOL');

/**
 * 事件名称
 */
export type EventName = string | number | symbol;

/**
 * 事件处理函数
 */
export interface EventHandle {
  (...args: any[]): void | PromiseLike<void>;
}

/**
 * 任意事件处理函数
 */
export interface AnyEventHandle<TEventName extends EventName> {
  (event: TEventName, ...args: any[]): void | PromiseLike<void>;
}

/**
 * 单次事件处理函数元信息
 */
interface OnceHandleMeta<T extends EventHandle> {
  /**
   * 是否已触发
   */
  fired: boolean;
  /**
   * 原始事件处理函数
   */
  origin: T;
}

/**
 * 单次事件处理函数
 */
type OnceEventHandle<T extends EventHandle> = T & {
  /**
   * 单次事件处理函数元信息
   */
  [ONCE_HANDLE_META_SYMBOL]: OnceHandleMeta<T>;
};

/**
 * 判断是否是单次事件处理函数
 * @param handle 事件处理函数
 * @returns 是否是单次事件处理函数
 */
function isOnceHandle<T extends EventHandle>(handle: T): handle is OnceEventHandle<T> {
  return ONCE_HANDLE_META_SYMBOL in handle;
}

/**
 * 生成单次事件处理函数
 * @param handle 事件处理函数
 * @param off 取消事件监听
 * @returns 单次事件处理函数
 */
function onceWrapper<T extends EventHandle>(handle: T, off: () => void): OnceEventHandle<T> {
  let fired = false;

  const wrapped = (...args: any[]) => {
    if (!fired) {
      fired = true;

      off();

      handle(...args);
    }
  };

  (wrapped as OnceEventHandle<T>)[ONCE_HANDLE_META_SYMBOL] = {
    fired,
    origin: handle,
  };

  return wrapped as OnceEventHandle<T>;
}

/**
 * 事件触发器
 */
class Emitter<TEvents extends Record<keyof TEvents, EventHandle> = Record<EventName, EventHandle>> {
  /**
   * 指定事件处理函数集合
   */
  private _eventsMap: Map<keyof TEvents, TEvents[keyof TEvents][]>;

  /**
   * 任意事件处理函数数组
   */
  private _anyHandles: AnyEventHandle<keyof TEvents>[];

  /**
   * 构造事件触发器
   */
  constructor() {
    this._eventsMap = new Map();
    this._anyHandles = [];
  }

  /**
   * 获取任意事件处理函数数
   * @returns {number} 任意事件处理函数数
   */
  getAnyHandleCount() {
    return this._anyHandles.length;
  }

  /**
   * 获取指定事件处理数
   * @param event 事件名称
   * @returns 事件处理函数数
   */
  getHandleCount(event: keyof TEvents) {
    const handles = this._eventsMap.get(event);

    if (handles) {
      return handles.length;
    }

    return 0;
  }

  /**
   * 取消监听任意事件
   * @param handle 任意事件处理函数
   * @returns 是否取消成功
   */
  offAny(handle: AnyEventHandle<keyof TEvents>) {
    const index = this._anyHandles.findIndex((h) => {
      if (h === handle) {
        return true;
      }

      if (isOnceHandle(h)) {
        return h[ONCE_HANDLE_META_SYMBOL].origin === handle;
      }

      return false;
    });

    if (index > -1) {
      this._anyHandles.splice(index, 1);

      return true;
    }

    return false;
  }

  /**
   * 监听任意事件
   * @param handle 任意事件处理函数
   * @returns 取消监听任意事件
   */
  onAny(handle: AnyEventHandle<keyof TEvents>) {
    let removed = false;

    this._anyHandles.push(handle);

    return () => {
      if (removed) {
        return false;
      }

      removed = true;

      return this.offAny(handle);
    };
  }

  /**
   * 单次监听任意事件
   * @param handle 任意事件处理函数
   * @returns 取消监听任意事件
   */
  onceAny(handle: AnyEventHandle<keyof TEvents>) {
    const off = this.onAny(
      onceWrapper(handle, () => {
        off();
      }),
    );

    return off;
  }

  /**
   * 取消监听指定事件
   * @param event 事件名称
   * @param handle 事件处理函数
   * @returns 是否取消成功
   */
  off<TEventName extends keyof TEvents>(event: TEventName, handle: TEvents[TEventName]) {
    const handles = this._eventsMap.get(event);

    if (handles) {
      const index = handles.findIndex((h) => {
        if (h === handle) {
          return true;
        }

        if (isOnceHandle(h)) {
          return h[ONCE_HANDLE_META_SYMBOL].origin === handle;
        }

        return false;
      });

      if (index > -1) {
        if (handles.length === 1) {
          this._eventsMap.delete(event);
        } else {
          handles.splice(index, 1);
        }

        return true;
      }
    }

    return false;
  }

  /**
   * 监听指定事件
   * @param event 事件名称
   * @param handle 事件处理函数
   * @returns 取消监听事件
   */
  on<TEventName extends keyof TEvents>(event: TEventName, handle: TEvents[TEventName]) {
    let removed = false;

    const handles = this._eventsMap.get(event);

    if (handles) {
      handles.push(handle);
    } else {
      this._eventsMap.set(event, [handle]);
    }

    return () => {
      if (removed) {
        return false;
      }

      removed = true;

      return this.off(event, handle);
    };
  }

  /**
   * 单次监听指定事件
   * @param event 事件名称
   * @param handle 事件处理函数
   * @returns 取消监听事件
   */
  once<TEventName extends keyof TEvents>(event: TEventName, handle: TEvents[TEventName]) {
    const off = this.on(
      event,
      onceWrapper(handle, () => {
        off();
      }),
    );

    return off;
  }

  /**
   * 触发指定事件
   * @param event 事件名称
   * @param args 事件参数
   * @returns 是否有处理事件
   */
  emit<TEventName extends keyof TEvents>(
    event: TEventName,
    ...args: Parameters<TEvents[TEventName]>
  ) {
    let handled = false;

    const handles = this._eventsMap.get(event);

    if (handles) {
      const handlesSnapshot = [...handles];

      handlesSnapshot.forEach((handle) => {
        try {
          const response = handle(...args);

          if (isPromiseLike(response)) {
            response.then(
              () => {},
              (error) => {
                if (process.env.NODE_ENV !== 'production') {
                  throw error;
                }
              },
            );
          }

          handled = true;
        } catch (error) {
          if (process.env.NODE_ENV !== 'production') {
            throw error;
          }
        }
      });
    }

    const anyHandlesSnapshot = [...this._anyHandles];

    anyHandlesSnapshot.forEach((anyHandle) => {
      try {
        const response = anyHandle(event, ...args);

        if (isPromiseLike(response)) {
          response.then(
            () => {},
            (error) => {
              if (process.env.NODE_ENV !== 'production') {
                throw error;
              }
            },
          );
        }
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          throw error;
        }
      }
    });

    return handled;
  }
}

export default Emitter;
