import { Logger } from './types';
import global from './global';
import ProxyLogger from './ProxyLogger';
import DebugLogger from './DebugLogger';
import EventLogger from './EventLogger';
import LoggerManager from './LoggerManager';

const GLOBAL_LOGGER_MANAGER_SYMBOL = Symbol.for('@logger#global-logger-manager-symbol');

if (!(GLOBAL_LOGGER_MANAGER_SYMBOL in global)) {
  const rootLogger = new DebugLogger('@logger');
  const loggerManager = new LoggerManager(rootLogger);

  if (typeof Object.defineProperty === 'function') {
    Object.defineProperty(global, GLOBAL_LOGGER_MANAGER_SYMBOL, {
      get() {
        return loggerManager;
      },
    });
  } else {
    (global as any)[GLOBAL_LOGGER_MANAGER_SYMBOL] = loggerManager;
  }
}

const loggerManager: LoggerManager = (global as any)[GLOBAL_LOGGER_MANAGER_SYMBOL];

export { Logger, ProxyLogger, DebugLogger, EventLogger, LoggerManager, loggerManager };
