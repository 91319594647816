import { Logger } from './types';

class ProxyLogger<T = any> implements Logger<T> {
  private _logger: Logger<T>;

  constructor(logger: Logger<T>) {
    this._logger = logger;
  }

  getLogger() {
    return this._logger;
  }

  setLogger(logger: Logger<T>) {
    this._logger = logger;

    return this;
  }

  silly(message?: T, ...optionalParams: any[]) {
    return this._logger.silly(message, ...optionalParams);
  }

  debug(message?: T, ...optionalParams: any[]) {
    return this._logger.debug(message, ...optionalParams);
  }

  verbose(message?: T, ...optionalParams: any[]) {
    return this._logger.verbose(message, ...optionalParams);
  }

  info(message?: T, ...optionalParams: any[]) {
    return this._logger.info(message, ...optionalParams);
  }

  warn(message?: T, ...optionalParams: any[]) {
    return this._logger.warn(message, ...optionalParams);
  }

  error(message?: T, ...optionalParams: any[]) {
    return this._logger.error(message, ...optionalParams);
  }
}

export default ProxyLogger;
